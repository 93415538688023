@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  color: #666;
}

div {
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

h2 {
  color: #333;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px;
}

a, .courseLink { /* Assuming .courseLink is a class for specific links */
  color: #007bff;
  text-decoration: none;
}

a:hover, .courseLink:hover {
  text-decoration: underline;
}



.container {
    text-align: center;
    padding: 20px;
    color: #666;;
  }
  
  .title {
    color: #0073bb;
    margin-bottom: 20px;
  }
  
  .image {
    max-width: 80%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .courseLink {
    display: inline-block;
    background-color: #0073bb;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .courseLink:hover {
    background-color: #005fa3;
  }
/* New styles for the Python course section */
.courseSection {
    margin-top: 40px;
    padding: 20px;
    background-color: #f0f0f0; /* Light grey background */
    border-radius: 8px;
  }
  
  .courseTitle {
    color: #004d99; /* Adjust the color to fit your theme */
  }
  
  .courseImage {
    max-width: 80%;
    height: auto;
    margin-top: 20px;
  }
    